import {RemixBrowser, useLocation, useMatches} from "@remix-run/react"
import * as Sentry from "@sentry/remix"
import {startTransition, StrictMode, useEffect} from "react"
import {hydrateRoot} from "react-dom/client"

import {isDevelopment} from "~/utilities/is-development"

Sentry.init({
  enabled:
    !isDevelopment(window.globalEnv.NODE_ENV) &&
    !!window.globalEnv.PUBLIC_SENTRY_DSN,
  dsn: window.globalEnv.PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  release: window.globalEnv.PUBLIC_SENTRY_RELEASE,
  environment: window.globalEnv.PUBLIC_SENTRY_ENV,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
